.mail {
  width: 100%;
  margin-top: 50px;
  background-color: #003580;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 0px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.mailInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mailInputContainer > input {
  width: 300px;
  height: 30px;
  padding: 10px;
  border: none;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  color: black;
}

.mailInputContainer > button {
  /* height: 50px; */
  background-color: #0071c2;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 4px 8px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .mailDesc {
    font-size: small;
  }

  .mailInputContainer > input {
    width: 100%;
    flex: 3;
    font-size: small;
  }
  .mailInputContainer > button {
    flex: 1;
  }
}
