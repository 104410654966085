// @import "../../mixins.scss";
// @mixin lg {
//   @media only screen and (min-width: 1024px) {
//     @content;
//   }
// }

.footer {
  background-color: #020c1b;
  padding: 50px 0;
  color: white;
  position: relative;
  .contentWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contentWrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    .menuItems {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-bottom: 20px;
      // @include md {
      //   margin-bottom: 30px;
      //   gap: 30px;
      // }
      .menuItem {
        transition: all ease 0.3s;
        cursor: pointer;
        font-size: 12px;
        //   @include md {
        //     font-size: 16px;
        //   }
        &:hover {
          color: #da2f68;
        }
      }
    }
    .infoText {
      font-size: 12px;
      line-height: 20px;
      opacity: 0.5;
      text-align: center;
      max-width: 800px;
      margin-bottom: 20px;
      // @include md {
      //   font-size: 14px;
      //   margin-bottom: 30px;
      // }
    }
    .socialIcons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #04152d;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
          box-shadow: 0 0 0.625em #da2f68;
          color: #da2f68;
        }
      }
    }
  }
}
