.header {
  background-color: #003580;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
  padding: 0px 15px;
}

.headerContainer {
  width: 100%;
  max-width: 1024px;
  margin: 20px 0px 100px 0px;
}

.headerContainer.listMode {
  margin: 20px 0px 0px 0px;
}

.headerList {
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
}

.headerListItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerListItem.active {
  border: 1px solid white;
  padding: 10px;
  border-radius: 20px;
}

.headerDesc {
  margin: 20px 0px;
}

.headerBtn {
  background-color: #0071c2;
  color: white;
  font-weight: 500;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.headerSearch {
  height: 55px;
  background-color: white;
  border: 3px solid #febb02;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px;
  border-radius: 5px;
  position: absolute;
  bottom: -25px;
  width: 100%;
  max-width: 900px;
  gap: 3rem; /*i am here*/
}

.headerIcon {
  color: lightgray;
  height: 1.8rem;
}

.headerSearchItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerSearchInput {
  border: none;
  outline: none;
}

.headerSearchText {
  color: lightgray;
  cursor: pointer;
  font-size: 12px;
}

.date {
  position: absolute;
  top: 50px;
  z-index: 5;
}

.options {
  z-index: 2;
  position: absolute;
  top: 50px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}

.optionItem {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.optionCounter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: black;
}

.optionCounterButton {
  width: 30px;
  height: 30px;
  border: 1px solid #0071c2;
  color: #0071c2;
  cursor: pointer;
  background-color: white;
}

.optionCounterButton:disabled {
  cursor: not-allowed;
}

@media (max-width: 1024px) {
  .header {
    padding: 0rem 1.8rem;
  }

  .headerSearch {
    max-width: 768px;
  }
}

@media (max-width: 808px) {
  .headerSearch {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .headerSearch {
    max-width: 425px;
  }
  .headerSearchText {
    font-size: 12px;
  }
  headerSearchItem {
    gap: 8px;
  }
}

@media (max-width: 472px) {
  .headerSearch {
    max-width: 400px;
  }
}

@media (max-width: 447px) {
  .headerSearch {
    max-width: 380px;
  }
}

@media (max-width: 426px) {
  .headerSearch {
    max-width: 355px;
  }
  .headerSearchText {
    font-size: 8px;
  }
  .headerSearchItem {
    gap: 6px;
  }
  .headerSearchItem > input::placeholder {
    content: "Location";
  }

  .headerSearchInput {
    font-size: 10px;
  }
  .date {
    font-size: 10px;
    bottom: 50px;
  }
  .options {
    top: 60px;
    right: 0px;
  }
  .date {
    top: 60px;
    right: 0px;
  }
  .headerTitle {
    font-size: 1.8rem;
    font-weight: 700;
  }
}

@media (max-width: 398px) {
  .headerSearch {
    max-width: 340px;
  }
}

@media (max-width: 376px) {
  .headerSearch {
    max-width: 300px;
  }
  .headerList {
    gap: 18px;
  }

  /* .headerListItem span {
    font-size: 0.8rem;
    font-weight: 500;
  } */
  .headerTitle {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .headerDesc {
    font-size: 1rem;
    font-weight: 400;
  }
}

@media (max-width: 320px) {
  .headerSearch {
    max-width: 258px;
  }
  .headerListItem span {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .headerTitle {
    font-size: 1.3rem;
    font-weight: 700;
  }
  .headerDesc {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .headerSearchText {
    font-size: 0.3rem;
    word-wrap: break-word;
  }
}
