.homeContainer {
  /* width: 100%; */
  /* max-width: 1024px; */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.homeTitle {
  width: 1024px;
  font-size: 20px;
}

@media (max-width: 1024px) {
  .homeContainer {
    gap: 2rem;
    /* max-width: fit-content; */
    /* width: 100vw; */
  }
  .homeTitle {
    width: 60rem;
  }
}

@media (max-width: 768px) {
  .homeTitle {
    width: 44rem;
  }
}
