.searchItem {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
}

.siImg {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.siDesc {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 2;
}

.siTitle {
  font-size: 20px;
  color: #0071c2;
}

.siDistance {
  font-size: 12px;
}

.siMeal {
  font-size: 12px;
  background-color: #008009;
  color: white;
  width: max-content;
  padding: 3px;
  border-radius: 5px;
}

.siSubtitle {
  font-size: 12px;
  font-weight: bold;
}

.siFeatures {
  font-size: 12px;
}

.siCancelOp {
  font-size: 12px;
  color: #008009;
  font-weight: bold;
}

.siCancelOpSubtitle {
  font-size: 12px;
  color: #008009;
}

.siDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.siRating {
  display: flex;
  justify-content: space-between;
}

.siRating > span {
  font-weight: 500;
}
.siRating > button {
  background-color: #003580;
  color: white;
  padding: 5px;
  font-weight: bold;
  border: none;
}

.siDetailTexts {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.siPrice {
  font-size: 24px;
}

.siTaxOp {
  font-size: 12px;
  color: gray;
}

.siCheckButton {
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  padding: 10px 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 694px) {
  .searchItem {
    margin: 5px 5px;
    /* margin-top: 5px; */
  }
  .siImg {
    width: 150px;
  }
  .siTaxOp {
    font-size: 8px;
  }
  .siCheckButton {
    padding: 6px 3px;
    font-weight: 400;
    font-size: small;
  }
  .siPrice {
    font-size: 20px;
  }
  .siRating > button {
    padding: 4px 2px;
    font-size: small;
  }
  .siRating > span {
    /* padding: 4px 2px; */
    font-size: small;
    margin-right: 5px;
  }
  .siCancelOpSubtitle {
    font-size: x-small;
  }
  .siCancelOp {
    font-size: x-small;
  }
  .siFeatures {
    font-size: x-small;
  }
  .siSubtitle {
    font-size: x-small;
  }
  .siMeal {
    font-size: x-small;
  }
}

@media (max-width: 424px) {
  .siImg {
    width: 120px;
  }
  .siTaxOp {
    font-size: 6px;
  }
  .siCheckButton {
    padding: 6px 3px;
    font-weight: 400;
    font-size: x-small;
  }
  .siPrice {
    font-size: 16px;
  }
  .siRating > button {
    padding: 4px 2px;
    font-size: x-small;
  }
  .siRating > span {
    /* padding: 4px 2px; */
    font-size: x-small;
    margin-right: 5px;
  }
  .siCancelOpSubtitle {
    font-size: xx-small;
  }
  .siCancelOp {
    font-size: xx-small;
  }
  .siFeatures {
    font-size: xx-small;
  }
  .siSubtitle {
    font-size: xx-small;
  }
  .siMeal {
    font-size: xx-small;
  }
}
