@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.fnt {
  font-family: "Roboto", sans-serif;
}

.head {
  font-size: xx-large;
  font-weight: 500;
}
.head3 {
  font-size: x-large;
  font-weight: 500;
}

.headCnt {
  font-size: large;
  font-weight: 400;
}

.pSubHead {
  font-size: large;
  font-weight: 400;
}
.liCnt {
  font-size: medium;
}
.pSubSubHead {
  font-size: medium;
}

@media (max-width: 767px) {
  .head {
    font-size: x-large;
  }
  .head3 {
    font-size: large;
  }
  .headCnt {
    font-size: medium;
  }
  .pSubHead {
    font-size: medium;
  }
  .liCnt {
    font-size: small;
  }
  .pSubSubHead {
    font-size: smaller;
  }
}
