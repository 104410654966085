@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.about_container {
  padding: 3rem 3rem;
}

.first_container h1 {
  font-size: 4rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.greeting {
  font-size: 2rem;
  font-weight: 500;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.address {
  font-size: 2rem;
  font-weight: 400;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.about_hotel {
  font-size: 2rem;
  font-weight: 400;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.second_container {
  padding: 2rem 0;
}

.second_container h3 {
  font-weight: 700;
  font-size: 2rem;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.second_container ul {
  padding: 0 3rem;
}

.second_container ul li {
  padding: 1rem 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.third_container {
  padding: 2rem 0;
}

.third_container h3 {
  font-weight: 700;
  font-size: 2rem;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.third_container p {
  font-weight: 400;
  padding: 0 1.3rem;
  font-family: "Poppins", sans-serif;
}

.fourth_container {
  padding: 2rem 0;
}

.fourth_container h3 {
  font-weight: 700;
  font-size: 2rem;
  padding: 1.3rem 0;
  font-family: "Poppins", sans-serif;
}

.fourth_container ul {
  padding: 0 3rem;
}

.fourth_container ul li {
  padding: 1rem 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.fifth_container {
  padding: 2rem 0;
}

.fifth_container h3 {
  font-weight: 700;
  font-size: 2rem;
  padding: 2rem 0;
  font-family: "Poppins", sans-serif;
}

.fifth_container p {
  /* font-size: 2rem; */
  font-weight: 400;
  padding: 1.3rem 1.3rem;
  font-family: "Poppins", sans-serif;
}

.fifth_container ul {
  padding: 0 3rem;
}

.fifth_container ul li {
  padding: 1rem 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.sixth_container {
  padding: 2rem 0;
}

.sixth_container p {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 1.4rem 0;
  font-family: "Poppins", sans-serif;
}

span {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
